import {
  OAuth2Client,
  OAuth2AuthenticateOptions,
  OAuth2RefreshTokenOptions,
} from "@byteowls/capacitor-oauth2";

import { isPlatform } from "@ionic/vue";

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Browser } from '@capacitor/browser';
import { tap } from "rxjs";

interface AuthenticateOptions {
  
  emailLinkLogin?: boolean,
  idTokenHint?: string,
  silent?: boolean
}

// Secure Storage Constants.
const SECURE_STORE_ACCESS_TOKEN = "SECURE_STORE_ACCESS_TOKEN";
const SECURE_STORE_REFRESH_TOKEN = "SECURE_STORE_REFRESH_TOKEN";
const SECURE_STORE_EXPIRES_AT = "SECURE_STORE_EXPIRES_AT";
const SECURE_STORE_ISSUED_AT = "SECURE_STORE_ISSUED_AT";
const SECURE_STORE_AUTH_TYPE = "SECURE_STORE_AUTH_TYPE";
// const B2C_FORGOT_PASSWORD_CODE = "AADB2C90118";
const ERR_NO_AUTHORIZATION_CODE = "ERR_NO_AUTHORIZATION_CODE";

export default class Auth {
  // Helper Methods

  static getCurrentTimeInMs(): number {
    return Math.floor(Date.now());
  }

  static getMsTimestamp(timestamp: number) {
    if (
      Math.abs(Date.now() - timestamp) < Math.abs(Date.now() - timestamp * 1000)
    ) {
      return timestamp;
    } else {
      return timestamp * 1000;
    }
  }

  static getOidcOptions(options: AuthenticateOptions): OAuth2AuthenticateOptions {
    
    const {emailLinkLogin, silent, idTokenHint} = options;

    const policy = emailLinkLogin === true
        ? process.env.VUE_APP_AUTH_EMAIL_LINK_SIGNIN_POLICY_NAME
        : process.env.VUE_APP_AUTH_SIGNIN_POLICY_NAME;

    const authOptions = {
      appId: process.env.VUE_APP_AUTH_CLIENT_ID,
      authorizationBaseUrl: `https://${process.env.VUE_APP_AUTH_TENANT_NAME}.b2clogin.com/${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize`,
      scope: `openid offline_access https://${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${process.env.VUE_APP_AUTH_CLIENT_ID}/alphavictor`,
      accessTokenEndpoint: `https://${process.env.VUE_APP_AUTH_TENANT_NAME}.b2clogin.com/${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${policy}/oauth2/v2.0/token`,
      responseType: "code",
      pkceEnabled: true,
      logsEnabled: true,
      web: {
        redirectUrl: `${process.env.VUE_APP_BASE_URL}/auth`,
        windowOptions: "height=600,left=0,top=0",
        windowTarget: silent ? "silentAuth" : "authWindow"
      },
      android: {
        redirectUrl:
          "msauth://com.alphavictorapp.app/lvGC0B4SWYU8tNPHg%2FbdMjQinZQ%3D",
      },
      ios: {
        pkceEnabled: true,
        redirectUrl: "msauth.com.alphavictorapp.app://auth",
      },
      additionalParameters: {},
    };

    if (emailLinkLogin === true && idTokenHint) {
      authOptions.additionalParameters = { ...authOptions.additionalParameters, id_token_hint: idTokenHint };
    }

    if (silent === true) {
      authOptions.additionalParameters = { ...authOptions.additionalParameters, prompt: 'none' };
    }

    return authOptions;
  }

  static getOidcRefreshOptions(
    refreshToken: string
  ): OAuth2RefreshTokenOptions {
    return {
      refreshToken: refreshToken,
      appId: process.env.VUE_APP_AUTH_CLIENT_ID,
      scope: `openid offline_access https://${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${process.env.VUE_APP_AUTH_CLIENT_ID}/alphavictor`,
      accessTokenEndpoint: `https://${process.env.VUE_APP_AUTH_TENANT_NAME}.b2clogin.com/${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${process.env.VUE_APP_AUTH_SIGNIN_POLICY_NAME}/oauth2/v2.0/token`,
    };
  }

  // Methods

  storeGuestAccessToken(guestToken: string, isAuthenticated: boolean) {
    const accessToken = localStorage.getItem(SECURE_STORE_ACCESS_TOKEN);

    // Don't overwrite an actual access token.
    if (accessToken && isAuthenticated) return;

    const issuedAtMs = Auth.getCurrentTimeInMs().toString();
    const expiresAtMs = (Auth.getCurrentTimeInMs() + 3600 * 1000).toString();

    localStorage.setItem(SECURE_STORE_ACCESS_TOKEN, guestToken);
    localStorage.removeItem(SECURE_STORE_REFRESH_TOKEN);
    localStorage.setItem(SECURE_STORE_ISSUED_AT, issuedAtMs);
    localStorage.setItem(SECURE_STORE_EXPIRES_AT, expiresAtMs);
    localStorage.setItem(SECURE_STORE_AUTH_TYPE, "Public");
  }

  async isTokenFresh(secondsMargin: number = 60 * 2): Promise<boolean> {
    const accessToken = localStorage.getItem(SECURE_STORE_ACCESS_TOKEN);
    const expiresAt = localStorage.getItem(SECURE_STORE_EXPIRES_AT);

    if (!accessToken || !expiresAt) {
      return false;
    }

    if (expiresAt) {
      // Make sure we're comparing time in the same units - JWT resposne is in Seconds, Web works better with Milliseconds, Android library does conversions.
      const expiresAtVal = Number.parseInt(expiresAt);
      const expiresAtValMs = Auth.getMsTimestamp(expiresAtVal);
      const now = Auth.getCurrentTimeInMs();
      const expire = expiresAtValMs - secondsMargin * 1000;

      return now < expire;
    }

    // if there is no expiration time but we have an access token, it is assumed to never expire
    return true;
  }

  async authenticate(options: AuthenticateOptions): Promise<boolean> {
    
    const {emailLinkLogin, idTokenHint, silent} = options;

    const oidcOptions = Auth.getOidcOptions(options);

    let silentWindow : Window | null = null;

    if (silent) silentWindow = window.open('#', 'silentAuth');

    try {
      const resp = await OAuth2Client.authenticate(oidcOptions);
            const accessToken = resp["access_token_response"]["access_token"];
      const refreshToken = resp["access_token_response"]["refresh_token"];
      let expiresAt = resp["access_token_response"]["expires_at"];

      if (!expiresAt) {
        expiresAt = resp["access_token_response"]["expires_on"];
      }

      const issuedAt = Auth.getCurrentTimeInMs().toString();
      localStorage.setItem(SECURE_STORE_ACCESS_TOKEN, accessToken);
      localStorage.setItem(SECURE_STORE_REFRESH_TOKEN, refreshToken);
      localStorage.setItem(SECURE_STORE_EXPIRES_AT, expiresAt);
      localStorage.setItem(SECURE_STORE_ISSUED_AT, issuedAt);
      localStorage.setItem(
        SECURE_STORE_AUTH_TYPE,
        emailLinkLogin ? "EmailLinkLogin" : "UsernamePassword"
      );

      return true;
    } catch (err: any) {
      const strErrorMessage = err.toString();

      if (strErrorMessage.indexOf(ERR_NO_AUTHORIZATION_CODE) > -1) {
        //Router doesnt seem to work here for some reason?
        // router.push({ path: '/forgot-password', replace: true })
        console.log("Reditecting to reset password")
        return false;
      } 
      //This causes error msg to appear
      return true;
    } finally {
      silentWindow?.close();
    }
  }

  async refresh(): Promise<boolean> {
    let refreshToken = localStorage.getItem(SECURE_STORE_REFRESH_TOKEN);
    const authType = localStorage.getItem(SECURE_STORE_AUTH_TYPE);

    if (!refreshToken || refreshToken === "undefined") return false;
    if (!authType || authType === "undefined") return false;

    if (authType == "Public") return false;

    try {
      const oidcRefreshOptions = Auth.getOidcRefreshOptions(refreshToken);
      const oidcAuthOptions = Auth.getOidcOptions({
        emailLinkLogin: authType === "EmailLinkLogin",
      });

      if (!isPlatform("hybrid")) {
        // We need to do the refresh call ourselves.

        const endpoint = oidcAuthOptions.accessTokenEndpoint;

        if (!endpoint) return false;

        const rawResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            grant_type: "refresh_token",
            client_id: oidcRefreshOptions.appId,
            refresh_token: oidcRefreshOptions.refreshToken,
            redirect_uri: oidcAuthOptions.redirectUrl ?? "",
          }),
        });

        const resp = await rawResponse.json();

        const accessToken = resp["access_token"];
        refreshToken = resp["refresh_token"];
        const expiresAt = resp["expires_on"];
        const issuedAt = Auth.getCurrentTimeInMs().toString();

        localStorage.setItem(SECURE_STORE_ACCESS_TOKEN, accessToken);
        localStorage.setItem(
          SECURE_STORE_REFRESH_TOKEN,
          refreshToken as string
        );
        localStorage.setItem(SECURE_STORE_EXPIRES_AT, expiresAt);
        localStorage.setItem(SECURE_STORE_ISSUED_AT, issuedAt);
      } else {
        const resp = await OAuth2Client.refreshToken(oidcRefreshOptions);

        const accessToken = resp["access_token"];
        refreshToken = resp["refresh_token"];
        let expiresAt = resp["expires_at"];
        const issuedAt = Auth.getCurrentTimeInMs().toString();

        if (!expiresAt) {
          expiresAt = resp["expires_on"];
        }

        localStorage.setItem(SECURE_STORE_ACCESS_TOKEN, accessToken);
        localStorage.setItem(
          SECURE_STORE_REFRESH_TOKEN,
          refreshToken as string
        );
        localStorage.setItem(SECURE_STORE_EXPIRES_AT, expiresAt);
        localStorage.setItem(SECURE_STORE_ISSUED_AT, issuedAt);
      }

      return true;
    } catch (e) {
      console.log("Refresh error: " + e);
      return false;
    }
  }

  async fetchCurrentAccessToken(): Promise<string | null> {
    const isTokenValid = await this.isTokenFresh();

    // If it is not valid, refresh first. If we're not logged in at all, this will fall through to a false.
    if (!isTokenValid) {
      console.log("Refreshing");

      const couldRefresh = await this.refresh();

      console.log(`Refresh status: ${couldRefresh}`);

      if (!couldRefresh) return null;
    }

    // If we're here, we should have an access token.
    const accessToken = localStorage.getItem(SECURE_STORE_ACCESS_TOKEN);

    if (!accessToken) return null;

    const accessTokenVal = accessToken;

    return accessTokenVal;
  }

  async logout(): Promise<boolean> {
    
    try {

      const authType = localStorage.getItem('SECURE_STORE_AUTH_TYPE');

      const policy = authType === 'EmailLinkLogin' ? process.env.VUE_APP_AUTH_EMAIL_LINK_SIGNIN_POLICY_NAME : process.env.VUE_APP_AUTH_SIGNIN_POLICY_NAME;

      localStorage.clear();

      let url = `https://${process.env.VUE_APP_AUTH_TENANT_NAME}.b2clogin.com/${process.env.VUE_APP_AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${policy}&post_logout_redirect_uri=`

      const isHybrid = isPlatform('hybrid');

      if (isHybrid && isPlatform('ios')) {
        
        url += 'msauth.com.alphavictorapp.app://auth';

        Browser.addListener('browserPageLoaded', async () => {

          await Browser.close();
          await Browser.removeAllListeners();
        });

        await Browser.open({url: url});

      } 
      else if (isHybrid && isPlatform('android')) {

        url += 'msauth://com.alphavictorapp.app/lvGC0B4SWYU8tNPHg%2FbdMjQinZQ%3D';

        const iab = InAppBrowser.create(url, '_system', 'hidden=yes');
        iab.on('loadstop').pipe(tap(() => iab.close()));

      } else {
        
        url += 'about:blank';

        // Web
        window.open(url, 'silentAuth');
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
