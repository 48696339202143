import axios from "axios";
import Auth from "@/auth";
import toastService from "@/services/toastService";
import loadingService from "@/services/loadingService";
import * as Sentry from "@sentry/capacitor";
import router from "./router";
import { auth as useAuthStore } from "@/stores/authStore";
import { Organisations as useOrganisationStore } from "@/stores/publicOrganisations";
import { Account as useAccountStore } from "@/stores/publicAccount";

const adminAPI = axios.create({
  baseURL: process.env.VUE_APP_API_ADMIN_URL,
});

const publicAPI = axios.create({
  baseURL: process.env.VUE_APP_API_PUBLIC_URL,
});

const globalAPI = axios.create({
  baseURL: process.env.VUE_APP_API_PUBLIC_URL,
});

const authService = new Auth();

adminAPI.interceptors.request.use(async (c) => {
  const accessToken = await authService.fetchCurrentAccessToken();

  if (accessToken) {
    c.headers.Authorization = `Bearer ${accessToken}`;
  }

  return c;
});

adminAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const formattedHandledError = await handleError(error);

    const errorObj = {
      ...error,
      message: formattedHandledError,
      toString: function() {
        return this.message
      }
    }

    return Promise.reject(errorObj);
  }
);

publicAPI.interceptors.request.use(async (c) => {
  const accessToken = await authService.fetchCurrentAccessToken();

  if (accessToken) {
    c.headers.Authorization = `Bearer ${accessToken}`;
  }

  return c;
});

publicAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const formattedHandledError = await handleError(error);

    const errorObj = {
      ...error,
      message: formattedHandledError,
      toString: function() {
        return this.message
      }
    }

    return Promise.reject(errorObj);
  }
);

globalAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const formattedHandledError = await handleError(error);

    const errorObj = {
      ...error,
      message: formattedHandledError,
      toString: function() {
        return this.message
      }
    }

    return Promise.reject(errorObj);
  }
);

const formatErrorMessage = (error: any) => {

  const errData = error?.response?.data;

  if (!errData) return "An unknown error occurred";

  if (errData && typeof errData === 'object') {

    let message = '';

    for (const key of Object.keys(errData)) {

      for (const errItem of errData[key]) {
        message = `${message}${errItem}\n`;
      }

    }

    return message;

  }
  else {

    const message = errData.message || errData.title || "An unknown error occurred";

    return message;
  }
  
}

const handleError = async (error: any) => {
  const authStore = useAuthStore();
  const accountStore = useAccountStore();
  const orgStore = useOrganisationStore();

  const status = error.response?.status;
  let message = error;
  if (error.config.headers["X-AV-ErrorHandler"] === "shortcode") {
    toastService.show(
      "No Spaces Found",
      "We couldn't find any spaces by that shortcode",
      "error",
      "bottom"
    );

    return '';

  } else {
    switch (status) {
      case 401:
        message = "Your login session has expired, please login again";
        authStore.setAuthStatus(false);
        accountStore.clearAccountDetails();
        accountStore.logoutPermission();
        orgStore.clearOrg();
        await orgStore.setOrgTheme();
        router.push("/");
        break;
      case 403:
        message = "You don't have permission to access this resource";
        break;
      case 404:
        message = "Sorry, that can't be found";
        break;
      case 408:
        message = "The server timed out waiting for the request";
        break;
      case 422:
        message = "The request was understood, but it could not be processed";
        break;
      case 500:
        message = "Whoops, something went wrong. If this issue persists, please contact AlphaVictor support";
        break;
      default:
        message = formatErrorMessage(error);
        break;
    }
    toastService.show("Error", message, "error", "bottom");
    Sentry.captureException(error);
  }

  loadingService.closeAll();

  return message;
};

export { adminAPI, publicAPI, globalAPI };
